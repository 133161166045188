<template>
  <div>
    <navigation-bar
      :background="'white'"
      :link="{
        name: 'project-interactions-filtered',
        params: { projectIdentifier, activityIdentifier: taskData.activity.identifier }
      }"
      label="Respuestas"
    />
    <div class="container is-widescreen page-bottom-padding">
      <div class="wrapper">
        <div class="columns is-centered">
          <div class="column standard-column">
            <div
              v-if="taskData !== null"
              class="task-container"
            >
              <task-preview
                :task="taskData"
                :show-title-complete="false"
                :show-description-complete="false"
              />
              <div class="responses-container">
                <div class="response-title">
                  Respuestas
                </div>
                <response
                  v-for="(response) in taskData.taskResponses"
                  :key="response.id"
                  :response="response"
                  :task-data="taskData"
                  :comments-count="response.commentsCount"
                  :display-comments="true"
                  :display-participant-tags="!isParticipant"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import TaskPreview from '@/components/TaskPreview.vue'
import NavigationBar from '@/components/NavigationBar'
import Response from '@/components/interactions/Response.vue'

export default {
  name: 'InteractionsTaskDetails',
  components: { TaskPreview, NavigationBar, Response },
  computed: {
    ...mapState('interactionsInteractions', ['taskData']),

    projectIdentifier() {
      return this.$route.params.projectIdentifier
    }
  }
}
</script>

<style lang="scss">
.task-container {
  .form-item {
    padding: 16px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0px 4px 6px rgba(200, 200, 200, 0.24);

    .form-item__header {
      .form-item__title {
        width: 100%;
        text-align: left;
        font-size: 22px !important;
        margin-bottom: 0px;
      }

      .form-item__mandatory {
        display: none;
      }
    }

    .form-item__description {
      text-align: left;
      font-size: 16px;
      color: black;
    }

    .form-item__fields {
      text-align: left;

      .open-question-without-media {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.task-container {
  .responses-container {
    .response-title {
      margin-bottom: 16px;
      text-align: left;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    .response-container {
      padding-left: 0px;
    }
  }
}
</style>
