<template>
  <div class="form-item">
    <div class="form-item__header">
      <div class="form-item__title">
        {{ taskTitle }}
      </div>
      <div
        class="form-item__mandatory"
        :class="mandatory"
      >
        {{ mandatory }}
      </div>
    </div>

    <media
      :model="task"
      type="question"
      :media-width="900"
      class="task-media"
    />

    <div
      v-if="hasDescription"
      class="form-item__description task-description"
      v-html="taskDescription"
    />

    <div class="form-item__fields">
      <template v-if="isNPS">
        <div :class="task.taskType">
          <taskNPS :task="task" />
        </div>
      </template>
      <template v-else-if="isSES">
        <div :class="task.task_type">
          <taskSES
            :task="task"
            mode="preview"
          />
        </div>
      </template>
      <template v-else-if="isOpenQuestion">
        <div
          v-if="isResponseWithMedia"
          class="open-question-with-media"
        >
          <div
            v-for="(number, i) in responseMediaFilesRange"
            :key="i"
            class="media-item column is-one-third"
            :class="isOneMediaFileClass"
          >
            <div class="media-item-container">
              <div class="donwload-icon">
                <img
                  src="../assets/question/icon-question-media-hover.svg"
                  alt="Descargar"
                >
              </div>
              <button>
                <img src="../assets/question/icon-question-upload.svg"><span>Explorar archivos</span>
              </button>
            </div>
            <div class="media-item-description">
              Descripción (Opcional)
            </div>
          </div>
        </div>
        <div
          v-else
          class="open-question-without-media"
        >
          Respuesta Libre (Máximo 500 caracteres)
        </div>
      </template>
      <template v-else>
        <task-preview-options :task="task" />
      </template>
    </div>
  </div>
</template>

<script>
import SharedConstants from '@/shared/constants.json'
import { formatTaskTitle } from '@/format'

import Media from '@/components/Media.vue'
import TaskNPS from '@/components/open-questionnaire/task-nps/TaskNPS.vue'
import TaskSES from '@/components/open-questionnaire/task-ses/TaskSES.vue'
import TaskPreviewOptions from '@/components/TaskPreviewOptions.vue'
import { quillDataStringToHTML } from '@/quill'

export default {
  name: 'TaskPreview',
  components: {
    Media,
    TaskPreviewOptions,
    TaskNPS,
    TaskSES
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    showTitleComplete: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isMounted: false
    }
  },
  computed: {
    mandatory() {
      return this.task.required ? 'Obligatoria' : 'Opcional'
    },

    isOpenQuestion() {
      return this.task.taskType === SharedConstants.TASK_TYPE.OPEN_QUESTION
    },

    isNPS() {
      return this.task.taskType === SharedConstants.TASK_TYPE.NPS
    },

    isSES() {
      return this.task.taskType === SharedConstants.TASK_TYPE.SES
    },

    hasDescription() {
      return this.task.description !== ''
    },

    isResponseWithMedia() {
      return this.task.responseAllowedType === SharedConstants.TASK_RESPONSE_TYPE.ONLY_MEDIA
    },

    responseMediaFilesRange() {
      return new Array(this.task.responseMediaLimit)
    },

    isOneMediaFileClass() {
      return this.task.responseMediaLimit === 1 ? 'one-media-file' : ''
    },

    taskTitle() {
      return this.showTitleComplete ? this.task.title : formatTaskTitle(this.task.title)
    },

    taskDescription() {
      return quillDataStringToHTML(this.task.description)
    }
  },
  mounted() {
    this.isMounted = true
  }
}
</script>

<style lang="scss" scoped>
.form-item {
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  padding: 28px 25px;
  margin-bottom: 25px;
  box-shadow: $box-shadow;

  .form-item__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .form-item__title {
      font-size: 26px;
      font-weight: 400;
      letter-spacing: -0.7px;
      line-height: 32px;
      padding: 0 0;
      margin-bottom: 10px;
      width: 75%;
      box-shadow: none !important;
      border: none;
      color: $black;
    }

    .form-item__mandatory {
      font-size: 16px;
      color: $green;
      margin-top: 6px;
      user-select: none;

      &.Opcional {
        color: $gray;
      }
    }
  }

  .form-item__description {
    margin-bottom: 1.5em;
  }

  .task-media {
    margin-top: 12px;
  }

  .form-item__fields {
    .media-component {
      .media-container {
        margin-bottom: 8px;
      }
    }

    .open-question-with-media {
      display: flex;
      flex-wrap: wrap;

      .media-item {
        &.one-media-file {
          width: 100%;
        }

        .media-item-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 37px;
          min-height: 216px;
          background: #FAFCFD;
          border: 1px dashed rgba(10, 108, 255, 0.2);
          border-radius: 5px;

          .donwload-icon {
            width: 64px;
            height: 64px;
            background: #eef0fe;
            border-radius: 5.964px;
            padding: 20px 20px;
          }

          button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;

            background: #FFFFFF;
            border: 1px solid #0A6CFF;
            box-sizing: border-box;
            border-radius: 40px;
            margin-top: 16px;

            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #0A6CFF;

            img {
              margin-right: 8px;
            }
          }
        }

        .media-item-description {
          margin-top: 5px;
          border-bottom: 1px solid #D2D2D2;
          font-size: 16px;
          line-height: 32px;
          color: #9B9B9B;
        }
      }
    }
  }

  .question__form-media {
    margin-bottom: 15px;

    .cld-image {
      text-align: center;
    }
  }
}
</style>
