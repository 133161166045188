export const formattedTextQuillOptions = {
  theme: 'snow',
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ align: [] }],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  }
}

export const quillDataStringToHTML = (quillDataString) => {
  if (quillDataString) {
    return JSON.parse(quillDataString).html
  }

  return ''
}

export const quillDataToString = (delta, html) => JSON.stringify({ delta: JSON.stringify(delta), html })
