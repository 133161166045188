<template>
  <div>
    <div
      v-if="anyOptionHasMedia"
      class="columns is-multiline"
    >
      <div
        v-for="(option, i) in task.options"
        :key="i"
        class="column is-one-third"
      >
        <div
          v-if="hasMedia(i)"
          class="media-component"
        >
          <Media
            :model="option"
            type="option-preview"
            :media-width="280"
          />
        </div>
        <div
          :class="task.taskType"
        >
          {{ option.description }}
        </div>
        <div class="details">
          {{ option.details }}
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="(option, i) in task.options"
        :key="i"
        :class="[task.taskType, task.icons]"
      >
        <div>
          {{ option.description }}
          <div class="details">
            {{ option.details }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Media from '@/components/Media.vue'

export default {
  name: 'TaskPreviewOptions',
  components: {
    Media
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isMounted: false
    }
  },
  computed: {
    anyOptionHasMedia() {
      return this.task.options.filter((o) => o.mediaFile != null).length > 0
    }
  },
  mounted() {
    this.isMounted = true
  },

  methods: {
    hasMedia(index) {
      return this.task.options[index].mediaFile != null
    }
  }
}
</script>

<style lang="scss" scoped>
.simple_choice {
  background: url("../assets/question/icon-question-radiobutton.svg") left
    center no-repeat;
}

.multiple_choice {
  background: url("../assets/question/icon-question-checkbox.svg") left
    center no-repeat;
}

.simple_choice,
.multiple_choice {
  color: $black;
  background-size: 23px;
  padding: 0 0 0 35px;
  margin-bottom: 15px;
}

.rating {
  min-height: 26px;
  color: $black;
  padding: 0 0;
  padding-right: 205px;
  margin-bottom: 15px;

  &.star {
    background: url("../assets/question/icon-question-rating-group-star.svg") right center no-repeat;
  }

  &.like {
    background: url("../assets/question/icon-question-rating-group-like.svg") right center no-repeat;
  }

  &.radio_button {
    background: url("../assets/question/icon-question-rating-group-radio_button.svg") right center no-repeat;
  }

  &.heart {
    background: url("../assets/question/icon-question-rating-group-heart.svg") right center no-repeat;
  }

  @include respond(mobile) {
    padding-right: 105px;
    background-size: 100px 26px !important;
  }
}

.open_question {
  color: $gray-medium;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #d2d2d2;
}

.column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .media-component {
    width: 100%;

    .media-container {
      width: 100%;
    }
  }
}

.details {
  color:#6A6A6A;
}
</style>
