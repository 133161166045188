<template>
  <div
    class="task-nps"
    :class="mode"
  >
    <div class="options">
      <div
        v-for="(option, index) in task.options"
        :key="index"
        class="option-container"
      >
        <div
          class="option"
          :class="{ 'selected': option.identifier == simpleChoiceSelectedOptionValue }"
          @click="selectOption(option.identifier.toString())"
        >
          <div class="option-content">
            {{ option.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="legends">
      <div class="legend">
        {{ task.options[0].details }}
      </div>
      <div class="legend">
        {{ task.options[9].details }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TaskNPS',
  props: {
    task: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: 'client'
    }
  },
  computed: {
    ...mapGetters('participationForm', ['simpleChoiceSelectedOptionValue']),
    ...mapState('participationForm', ['formData'])
  },
  methods: {
    selectOption(option) {
      this.$store.commit(
        'participationForm/updateSimpleChoiceValue',
        option === this.formData.optionValue ? '' : option
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.task-nps {

  &.participant {
    .options {
      .option {
        cursor: pointer;

        &.selected, &:hover {
          background: #DAEAFC;
          border: 1px solid $blue;
          box-sizing: border-box;
          box-shadow: 0px 0px 5px rgba(10, 108, 255, 0.5);

          .option-content {
            color: $blue;
          }
        }

        .option-content {
          color: #B8B8B8;
        }
      }
    }
  }

  .options {
    margin-top: 5px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .option-container {
      padding-bottom: 8px;
      flex-basis: 8%;

      @include respond(mobile) {
        flex-basis: 18%;
      }

      .option {
        display: inline-block;
        background: #f9f9f9;
        border: 1px solid #d4d4d4;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        position: relative;
        min-height: 52px;

        .option-content {
          position: absolute;
          width: 100%;
          height: 100%;
          text-align: center;
          padding-top: 30%;
          color: #d4d4d4;
          font-size: 16px;
          user-select: none;
        }
      }

      .option:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }

    }
  }

  .legends {
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;

    .legend {
      padding-bottom: 0px;
      font-weight: 500;
      user-select: none;
    }
  }
}
</style>
