<template>
  <div class="question">
    <div class="header">
      <div class="title">
        {{ title }}
        <span>*</span>
      </div>

      <div class="description">
        {{ description }}
      </div>

      <b-field>
        <b-input
          v-if="isTypeNumber"
          v-model="localValue"
          type="number"
          min="1"
          :max="inputMaxValue"
          :placeholder="inputPlaceHolder"
          @input="inputEvent"
        />

        <div
          v-if="isTypeOptions"
        >
          <div
            v-for="(option, i) in inputOptions"
            :key="i"
            class="field"
          >
            <b-radio
              v-model="localValue"
              size="is-medium"
              :native-value="option.value"
              @input="inputEvent"
            >
              {{ option.description }}
            </b-radio>
          </div>
        </div>
      </b-field>
    </div>

    <b-notification
      v-if="validationError"
      type="is-danger"
      role="alert"
    >
      Debes completar esta pregunta
    </b-notification>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TaskSESQuestion',
  props: {
    modelName: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: NaN
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    inputType: {
      type: String,
      default: null
    },
    inputPlaceHolder: {
      type: String,
      default: null
    },
    inputOptions: {
      type: Array,
      default: null
    },
    inputMaxValue: {
      type: Number,
      default: 15
    }
  },
  data() {
    return {
      localValue: this.value
    }
  },
  computed: {
    ...mapState('participationTaskSES', ['validationError']),

    isTypeNumber() {
      return this.inputType === 'number'
    },

    isTypeOptions() {
      return this.inputType === 'options'
    }
  },
  methods: {
    inputEvent() {
      this.$emit('value-changed', this.modelName, this.localValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.question {
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  padding: 28px 25px;
  margin-bottom: 25px;
  box-shadow: 0px 6px 10px rgb(0 0 0 / 3%);

  .header {
    text-align: left;

    .title {
      font-size: 26px !important;
      font-weight: 400;
      letter-spacing: -0.7px;
      line-height: 32px;
      padding: 0 0;
      margin-bottom: 10px;
      box-shadow: none !important;
      border: none;
      color: #000000;

      span {
        font-weight: 600;
        color: $green;
      }
    }
  }

  .field {
    margin-top: 10px;
  }
}
</style>
<style lang="scss">
.notification {
  padding: 15px 15px;
  user-select: none;

  &.is-danger {
    background-color: #ffdae2;
    color: #e20000;
    font-weight: 500;
    margin: 15px 0px;

    @include respond(mobile) {
      padding: 10px 10px;
      font-size: 15px;
    }
  }

  .delete {
    display: none !important;
  }
}
</style>
