<template>
  <div class="general-container grey-background">
    <the-navbar />
    <task-details v-if="!isLoading" />
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false"
      class="fill-screen"
    />
  </div>
</template>

<script>
import TaskDetails from '@/components/interactions/TaskDetails.vue'
import TheNavbar from '@/components/TheNavbar.vue'

export default {
  name: 'TaskInteractions',
  components: {
    TaskDetails,
    TheNavbar
  },

  data() {
    return {
      isLoading: true
    }
  },

  async created() {
    // Fetch the task data
    const params = {
      projectIdentifier: this.$route.params.projectIdentifier,
      taskIdentifier: this.$route.params.taskIdentifier
    }

    await this.$store.dispatch('interactionsInteractions/getTaskData', params)

    if (!this.isParticipant) {
      const success = await this.$store.dispatch(
        'staffParticipants/getParticipantsData',
        {
          projectIdentifier: this.$route.params.projectIdentifier
        }
      )

      if (success) this.isLoading = false
    } else {
      this.isLoading = false
    }
  }
}
</script>
