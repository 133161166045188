<template>
  <div
    class="task-ses"
    :class="mode"
  >
    <div class="preview-view">
      Esta consigna contendrá una seríe de preguntas que nos permitirán definir el nivel socio econímico del
      participante según la Asociación Argentina de Marketing.
    </div>

    <TaskSESQuestion
      v-if="showNumberOfMembers"
      :key="propertiesNames.numberOfMembers"
      :model-name="propertiesNames.numberOfMembers"
      :value="response.numberOfMembers"
      title="¿Cuántas personas viven en tu hogar, incluyendote a vos?"
      input-type="number"
      input-place-holder="Personas en tu hogar"
      @value-changed="valueChanged"
    />

    <TaskSESQuestion
      v-else-if="showMembersWhoHaveIncome"
      :key="propertiesNames.membersWhoHaveIncome"
      :model-name="propertiesNames.membersWhoHaveIncome"
      :value="response.membersWhoHaveIncome"
      title="¿Cuántas de ellas tienen ingresos de algún tipo, tanto de trabajo personal como de otras fuentes?"
      description="Por “ingresos de otras fuentes” entendemos alquileres, becas, ayuda familiar, subsidios por
          desocupación, renta financiera o inversión en negocios en los que no trabaja"
      input-type="number"
      input-place-holder="Personas con ingresos"
      :input-max-value="response.numberOfMembers"
      @value-changed="valueChanged"
    />

    <TaskSESQuestion
      v-else-if="showEducationLevel"
      :key="propertiesNames.educationLevel"
      :model-name="propertiesNames.educationLevel"
      :value="response.educationLevel"
      title="¿Cuál es el máximo nivel de estudios que alcanzó el principal sostén del hogar?"
      input-type="options"
      :input-options="propertiesOptions.educationLevel"
      @value-changed="valueChanged"
    />

    <TaskSESQuestion
      v-else-if="showMedicalCoverage"
      :key="propertiesNames.medicalCoverage"
      :model-name="propertiesNames.medicalCoverage"
      :value="response.medicalCoverage"
      title="¿El principal sostén del hogar tiene actualmente algún tipo de cobertura médica asegurada,
        una obra social o medicina prepaga?"
      input-type="options"
      :input-options="propertiesOptions.medicalCoverage"
      @value-changed="valueChanged"
    />

    <TaskSESQuestion
      v-else-if="showWorkStatus"
      :key="propertiesNames.workStatus"
      :model-name="propertiesNames.workStatus"
      :value="response.workStatus"
      title="¿Actualmente el principal sostén del hogar está trabajando?"
      input-type="options"
      :input-options="propertiesOptions.workStatus"
      @value-changed="valueChanged"
    />

    <TaskSESQuestion
      v-else-if="showWorkType"
      :key="propertiesNames.workType"
      :model-name="propertiesNames.workType"
      :value="response.workType"
      title="El trabajo del principal sostén del hogar es"
      input-type="options"
      :input-options="propertiesOptions.workType"
      @value-changed="valueChanged"
    />

    <TaskSESQuestion
      v-else-if="showWorkCategory"
      :key="propertiesNames.workCategory"
      :model-name="propertiesNames.workCategory"
      :value="response.workCategory"
      title="¿Cúal es la categoría de la ocupación del principal sostén del hogar?"
      input-type="options"
      :input-options="propertiesOptions.workCategory"
      @value-changed="valueChanged"
    />

    <TaskSESQuestion
      v-else-if="showWorkQualification"
      :key="propertiesNames.workQualification"
      :model-name="propertiesNames.workQualification"
      :value="response.workQualification"
      title="¿Cómo calificaría al principal sostén del hogar?"
      input-type="options"
      :input-options="propertiesOptions.workQualification"
      @value-changed="valueChanged"
    />

    <TaskSESQuestion
      v-else-if="showNumberOfEmployees"
      :key="propertiesNames.numberOfEmployees"
      :model-name="propertiesNames.numberOfEmployees"
      :value="response.numberOfEmployees"
      title="¿Cuál es la cantidad aproximada de empleados de la empresa en la que trabaja el principal
        sostén del hogar?"
      input-type="options"
      :input-options="propertiesOptions.numberOfEmployees"
      @value-changed="valueChanged"
    />

    <TaskSESQuestion
      v-else-if="showWorkIntensity"
      :key="propertiesNames.workIntensity"
      :model-name="propertiesNames.workIntensity"
      :value="response.workIntensity"
      title="Entre todas las ocupaciones o actividades laborales que pudiera tener actualmente el principal
        sostén del hogar, ¿trabaja al menos 35 horas en la semana?"
      input-type="options"
      :input-options="propertiesOptions.workIntensity"
      @value-changed="valueChanged"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { propertiesNames, propertiesOptions, propertiesPosition } from './taskSES'
import TaskSESQuestion from './TaskSESQuestion.vue'

export default {
  name: 'TaskSES',
  components: {
    TaskSESQuestion
  },
  props: {
    mode: {
      type: String,
      default: 'client'
    }
  },
  computed: {
    ...mapGetters('participationOpenQuestionnaire', ['currentTaskData', 'currentTaskId']),
    ...mapGetters('participationTaskSES', ['nextQuestionCode', 'prevQuestionCode', 'isResponseValid', 'SESValue']),
    ...mapState('participationTaskSES', ['currentQuestionCode', 'response']),

    showNumberOfMembers() {
      return this.currentQuestionCode === propertiesNames.numberOfMembers
    },

    showMembersWhoHaveIncome() {
      return this.currentQuestionCode === propertiesNames.membersWhoHaveIncome
    },

    showEducationLevel() {
      return this.currentQuestionCode === propertiesNames.educationLevel
    },

    showMedicalCoverage() {
      return this.currentQuestionCode === propertiesNames.medicalCoverage
    },

    showWorkStatus() {
      return this.currentQuestionCode === propertiesNames.workStatus
    },

    showWorkType() {
      return this.currentQuestionCode === propertiesNames.workType
    },

    showWorkCategory() {
      return this.currentQuestionCode === propertiesNames.workCategory
    },

    showWorkQualification() {
      return this.currentQuestionCode === propertiesNames.workQualification
    },

    showNumberOfEmployees() {
      return this.currentQuestionCode === propertiesNames.numberOfEmployees
    },

    showWorkIntensity() {
      return this.currentQuestionCode === propertiesNames.workIntensity
    }
  },

  mounted() {
    // Listen to 'Emit' (from OpenQuestionNavbar), and trigger "validateForm()" method
    this.$root.$on('validateForm', this.validateForm)
  },

  beforeDestroy() {
    this.$root.$off('validateForm')
  },

  created() {
    this.propertiesNames = propertiesNames
    this.propertiesOptions = propertiesOptions
    this.$store.dispatch('participationTaskSES/setInitialResponse')
  },

  methods: {
    validateForm() {
      if (this.isResponseValid) {
        this.$store.commit('participationTaskSES/setErrorMsg', false)

        const values = []
        values[propertiesPosition.numberOfMembers] = this.response.numberOfMembers
        values[propertiesPosition.membersWhoHaveIncome] = this.response.membersWhoHaveIncome
        values[propertiesPosition.educationLevel] = this.response.educationLevel
        values[propertiesPosition.medicalCoverage] = this.response.medicalCoverage
        values[propertiesPosition.workStatus] = this.response.workStatus
        values[propertiesPosition.workType] = this.response.workType
        values[propertiesPosition.workCategory] = this.response.workCategory
        values[propertiesPosition.workQualification] = this.response.workQualification
        values[propertiesPosition.numberOfEmployees] = this.response.numberOfEmployees
        values[propertiesPosition.workIntensity] = this.response.workIntensity

        const form = {
          optionValue: '',
          optionsValue: [],
          optionsResponses: [],
          value: values.join(',')
        }

        if (this.SESValue !== undefined && !Number.isNaN(this.SESValue)) {
          const option = this.currentTaskData.options.find((o) => o.order === this.SESValue)
          form.optionValue = option.id
        }

        let moveFoward = false

        if (this.currentQuestionCode !== this.nextQuestionCode) {
          this.$store.commit('participationTaskSES/setCurrentQuestionCode', this.nextQuestionCode)
        } else {
          moveFoward = true
        }

        this.$root.$emit('updateParticipation', { form, moveFoward })
      } else {
        this.$store.commit('participationTaskSES/setErrorMsg', true)
      }
    },

    valueChanged(propertyName, value) {
      this.$store.dispatch('participationTaskSES/valueChanged', { propertyName, value })
    }
  }
}
</script>

<style lang="scss" scoped>
.task-ses {

  &.preview {
    .question {
      display: none;
    }
  }

  &.participant {
    .preview-view {
      display: none;
    }
  }

  .question {
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 28px 25px;
    margin-bottom: 25px;
    box-shadow: 0px 6px 10px rgb(0 0 0 / 3%);

    .header {
      text-align: left;

      .title {
        font-size: 26px !important;
        font-weight: 400;
        letter-spacing: -0.7px;
        line-height: 32px;
        padding: 0 0;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none;
        color: #000000;

        span {
          font-weight: 600;
          color: $green;
        }
      }
    }

    .field {
      margin-top: 20px;
    }
  }
}
</style>
